import * as React from 'react';

// @mui
import { Container } from "@mui/material";

// hooks
import useSettings from '../../hooks/useSettings';
// components
import DashboardLayout from '../../layouts/dashboard';
import Page from '../../components/Page';

import { PoolOperatorDetail } from "../../sections";
import {AddressDisplay} from "../../utils/formatSubstrate";

// ----------------------------------------------------------------------

export default function PoolOperatorDetailPage({operatorId,operatorLabel=undefined}) {
    const { themeStretch } = useSettings();

    if(operatorLabel===undefined) operatorLabel=AddressDisplay(operatorId);

    return (
        <DashboardLayout>
          <Page title={`Pool Operator Detail: ${operatorLabel}`}>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <PoolOperatorDetail operatorId={operatorId} operatorLabel={operatorLabel}/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
